.constelaciones {
    margin: 1rem;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}

th {
    font-size: 85%;
}

th {
    background-color: black;
    font-weight: bold;
    color: white
}

.constelacion-item:hover {
    background-color: #928f8f;
    color: black;
}
