body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  text-align: center;
  background-color: #282c34;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

footer {
  margin-top: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  color: #aaa;
}

footer a {
  color: inherit;
}

html[data-color-mode=dark] {
  background-color: #282c34;
}

.btn {
  background-color: #97876A !important;
  color: black !important;
}

.btn:hover {
  background-color: white !important;
}
